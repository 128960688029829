import { render, staticRenderFns } from "./disciplines.vue?vue&type=template&id=15362f6d&scoped=true"
import script from "./disciplines.vue?vue&type=script&lang=js"
export * from "./disciplines.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15362f6d",
  null
  
)

export default component.exports