<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="create()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="DisciplineFind"
        mobile-breakpoint="0"
        sort-by="name"
        :search="filter"
        :items-per-page="15"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'name', value)"
          />
        </template>
        <template #item.shortName="{item}">
          <base-edit-dialog
            :value="item.shortName"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'shortName', value)"
          />
        </template>
        <template #item.regular="{item}">
          <base-edit-dialog-select
            :value="item.regular"
            :id="item._id"
            :items="[
              { value: true, text: 'ja' },
              { value: false, text: 'nein' }
            ]"
            label="regulär"
            :clearable="false"
            @input="({ value, id }) => savefield(id, 'regular', value, 'Boolean')"
          />
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item)"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      collection="Discipline"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { apolloDefault, useGraphQL } from '@/plugins/graphql'
import Identifiers from '../../core/dialogs/identifiers'

const query = `
  _id
  name
  shortName
  regular
  logo
  identifiers { name value }
`

export default {
  name: 'disciplines',

  components: {
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    DisciplineFind: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Kurzname',
        value: 'shortName',
        sortable: true
      },
      {
        text: 'regulär',
        value: 'regular',
        sortable: true
      },
      /* {
        text: 'Logo',
        value: 'logo',
        sortable: false
      }, */
      {
        text: 'IDs',
        value: 'identifiers',
        sortable: false
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    filter: '',
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: ''
    }
  }),

  methods: {
    create () {
      this.mutate({
        mutation: gql`
          mutation { DisciplineCreate { ${query} }}
          `
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { DisciplineDelete(id: $id) }
          `,
        variables: {
          id
        }
      })
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            DisciplineUpdate${q}(id: $id, ${field}: $${field}) { ${query} }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    openidentifiers (item) {
      this.identifiersdialog.id = item._id || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.open = true
    }
  },

  watch: {
    DisciplineFind: {
      deep: true,
      handler (val) {
        if (!this.identifiersdialog.open) return
        this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
      }
    }
  },

  apollo: apolloDefault('Discipline', query)
}
</script>

<style scoped>

</style>
